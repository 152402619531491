















import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  data() {
    return {
      v: '2021-04',
    };
  },
  computed: {
    d(): string {
      return this.$d(new Date(this.v), 'MONTH_YEAR');
    },
  },
});
